import { Views } from 'react-big-calendar';
import { Entity } from './interfaces';

export const calendarProps = (updateEntity: any, entity: Entity) => ({
  max: new Date(1972, 0, 1, 21, 59, 59),
  min: new Date(1972, 0, 1, 7, 0, 0),
  defaultDate: new Date(),
  views: [Views.MONTH, Views.WEEK, Views.DAY],
  messages: {
    date: 'Datum',
    time: 'Vrijeme',
    event: 'Event',
    allDay: 'Cijeli dan',
    week: 'Tjedan',
    work_week: 'Radni tjedan',
    day: 'Dan',
    month: 'Mjesec',
    previous: 'Prethodni',
    next: 'Sljedeći',
    yesterday: 'Jučer',
    tomorrow: 'Sutra',
    today: 'Danas',
    agenda: 'Agenda',

    noEventsInRange: 'Nema eventa u zadanom rasponu',

    showMore: (total: number) => `+${total} dodatnih`,
  },
  additionalOptions: [
    {
      handler: async () => {
        const result = await updateEntity({
          variables: {
            uuid: entity.uuid,
            set: { is_active: !entity.is_active },
          },
        });
        return result;
      },
      label: entity.is_active
        ? 'Deaktiviraj djelatnost'
        : 'Aktiviraj djelatnost',
    },
  ],
});
