import { useState, useMemo, useEffect } from 'react';
import debounce from 'lodash/debounce';

import { HandleNameChangeInterface } from '../../interfaces';

import styles from './searchBar.module.scss';

interface SearchBarProps {
  searchHandler: (query: string | undefined) => void;
  searchQuery: string | undefined;
}
const SearchBar = ({ searchHandler, searchQuery }: SearchBarProps) => {
  const [query, setQuery] = useState<string | undefined>(searchQuery);

  useEffect(() => {
    searchHandler(query);
  }, [query]);

  const changeHandler = (event: HandleNameChangeInterface) => {
    setQuery(event.target.value);
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);

  return (
    <div className={styles.searchBar}>
      <label>Pretraga:</label>
      <span className={styles.searchBarSpan}>
        <input
          placeholder="Pretražite korisnike po imenu ili prezimenu"
          type="search"
          autoFocus
          defaultValue={searchQuery}
          onChange={debouncedChangeHandler}
        />
      </span>
    </div>
  );
};

export default SearchBar;
