import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const RoleRoute = ({ children, roles }: { children: any; roles: any }) => {
  const { user } = useAuth0();

  const isRouteAllowed = roles.some(
    (role: any) => role === user?.defaultgymadminrole
  );

  if (isRouteAllowed) {
    return <>{children}</>;
  }
  return <Navigate to="/dashboard/" />;
};

export default RoleRoute;
