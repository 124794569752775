import { ManipulateType } from 'dayjs';

export enum membershipDuration {
  NUMBERED,
  DAILY,
  WEEKLY,
  MONTHLY,
  VIP,
}

export enum membershipID {
  NUMBERED,
  DAILY,
  WEEKLY,
  BI_WEEKLY,
  MONTHLY,
  THREE_MONTHS,
  SIX_MONTHS,
  NINE_MONTHS,
  YEARLY,
  STUDENTS,
  SJD_STUDENTS,
  MUP,
  OB_PULA,
  VIP,
}

interface dateModifierType {
  [key: number]: ManipulateType;
}
export const dateModifier: dateModifierType = {
  [membershipDuration.DAILY]: 'day',
  [membershipDuration.WEEKLY]: 'week',
  [membershipDuration.MONTHLY]: 'month',
};
export const membershipTypes = [
  {
    id: membershipID.NUMBERED,
    type: membershipDuration.NUMBERED,
    duration: 12,
    description: 'dvanaest dolazaka',
  },
  {
    id: membershipID.DAILY,
    type: membershipDuration.DAILY,
    duration: 1,
    description: 'dnevna članarina',
  },
  {
    id: membershipID.WEEKLY,
    type: membershipDuration.WEEKLY,
    duration: 1,
    description: 'tjedna članarina',
  },
  {
    id: membershipID.BI_WEEKLY,
    type: membershipDuration.WEEKLY,
    duration: 2,
    description: 'dvotjedna članarina',
  },
  {
    id: membershipID.MONTHLY,
    type: membershipDuration.MONTHLY,
    duration: 1,
    description: 'mjesečna članarina',
  },
  {
    id: membershipID.THREE_MONTHS,
    type: membershipDuration.MONTHLY,
    duration: 3,
    description: 'tromjesečna članarina',
  },
  {
    id: membershipID.SIX_MONTHS,
    type: membershipDuration.MONTHLY,
    duration: 6,
    description: 'šestomjesečna članarina',
  },
  {
    id: membershipID.NINE_MONTHS,
    type: membershipDuration.MONTHLY,
    duration: 9,
    description: 'devetomjesečna članarina',
  },
  {
    id: membershipID.YEARLY,
    type: membershipDuration.MONTHLY,
    duration: 12,
    description: 'godišnja članarina',
  },
  {
    id: membershipID.STUDENTS,
    type: membershipDuration.MONTHLY,
    duration: 1,
    description: 'studentska članarina',
  },
  {
    id: membershipID.SJD_STUDENTS,
    type: membershipDuration.MONTHLY,
    duration: 1,
    description: 'studentska - Sveučilište Jurje Dobrile',
  },
  {
    id: membershipID.MUP,
    type: membershipDuration.MONTHLY,
    duration: 1,
    description: 'djeltanici MUPa',
  },
  {
    id: membershipID.OB_PULA,
    type: membershipDuration.MONTHLY,
    duration: 1,
    description: 'djelatnici Opće Bolnice Pula',
  },
  {
    id: membershipID.VIP,
    type: membershipDuration.VIP,
    duration: 12,
    description: 'stalna članarina',
  },
];
