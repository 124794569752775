import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { updatedDiff } from 'deep-object-diff';
import Button from '@mui/material/Button';

import {
  INSERT_EMPLOYEE,
  UPDATE_EXISTING_EMPLOYEE,
} from '../../../graphQL/queries/employee';
import { DbGymEmployee } from '../../../interfaces';

import styles from './employeeForm.module.scss';

interface EmployeeFormProps {
  edit: boolean;
  editData?: DbGymEmployee;
  handleClose: () => void;
  refetch: () => void;
}

interface IFormInput {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  status: string;
  employee_role: string;
}

const EmployeeForm = ({
  edit,
  editData,
  handleClose,
  refetch,
}: EmployeeFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues:
      edit && editData
        ? editData
        : {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: 'legday12345!',
            status: 'ACTIVE',
            employee_role: 'USER',
          },
  });

  const gqlQuery = edit ? UPDATE_EXISTING_EMPLOYEE : INSERT_EMPLOYEE;
  const [insertEmployee] = useMutation(gqlQuery, {
    onCompleted: () => refetch(),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!data) return;
    const variables =
      edit && editData
        ? { uuid: editData.uuid, set: updatedDiff(editData, data) }
        : data;

    insertEmployee({ variables });
    handleClose();
  };

  const first_name = watch('first_name');
  const last_name = watch('last_name');
  const email = watch('email');
  const phone = watch('phone');
  const password = watch('password');

  return (
    <>
      <form
        autoComplete="off"
        className={styles.container}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.personalData}>
          <h3>Unesite podatke o zaposleniku</h3>
          <div className={styles.inputRow}>
            <div className={styles.inputHolder}>
              <input {...register('first_name', { required: true })} />
              <label
                className={first_name?.length > 0 ? styles.notEmpty : undefined}
              >
                Ime:
              </label>
            </div>
            <div className={styles.inputHolder}>
              <input
                className={errors.last_name ? styles.error : undefined}
                {...register('last_name', { required: true })}
              />
              <label
                className={last_name?.length > 0 ? styles.notEmpty : undefined}
              >
                Prezime:
              </label>
            </div>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputHolder}>
              <input
                className={errors.email ? styles.error : undefined}
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
              />
              <label
                className={email?.length > 0 ? styles.notEmpty : undefined}
              >
                Email:
              </label>
            </div>
            <div className={styles.inputHolder}>
              <input
                className={errors.phone ? styles.error : undefined}
                {...register('phone', {
                  required: true,
                })}
              />
              <label
                className={phone?.length > 0 ? styles.notEmpty : undefined}
              >
                Broj telefona:
              </label>
            </div>
          </div>
          {!(edit && editData) && (
            <>
              <input
                className={errors.password ? styles.error : undefined}
                {...register('password', {
                  required: true,
                })}
              />
              <label
                className={password?.length > 0 ? styles.notEmpty : undefined}
              >
                Lozinka:
              </label>
            </>
          )}
          <div className={styles.status}>
            <p>Status korisnika:</p>
            <div className={styles.radio}>
              <input
                {...register('status')}
                defaultChecked
                type="radio"
                value={'ACTIVE'}
              />
              <label>Aktivan</label>
            </div>
            <div className={styles.radio}>
              <input {...register('status')} type="radio" value={'DISABLED'} />
              <label>Ugašen</label>
            </div>
          </div>
        </div>
        <div>
          <p>
            {Object.keys(errors)?.length > 0 && (
              <span className={styles.error}>
                Polja u crvenom su obavezna kod upisa novog člana!
              </span>
            )}
          </p>
        </div>
        <div className={styles.buttons}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose()}
          >
            Odustani
          </Button>
          <Button
            variant="contained"
            color="success"
            type="submit"
            size="large"
          >
            Unesi
          </Button>
        </div>
      </form>
    </>
  );
};

export default EmployeeForm;
