import { useState, useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button } from '@mui/material';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import dayjs from 'dayjs';

import {
  UPDATE_EXISTING_ENTITY,
  GET_RESERVATIONS_BY_ID,
} from '../../graphQL/queries/reservations';

import ThreeDotMenuDelete from '../ThreeDotMenuDelete';
import EntitySlotForm from './EntitySlotForm';
import SpinningLoader from '../SpinningLoader';

import { calendarProps } from './helper';
import { Entity, Event, DbEvent } from './interfaces';

import styles from './entityPanel.module.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';

require('moment/locale/hr.js');
const localizer = momentLocalizer(moment);

interface EntityPanelProps {
  entity: Entity;
  handleOpen: (entity: any, oldData: boolean) => void;
  refetch: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// TODO: history of changes on reservations

const EntityPanel = ({ entity, handleOpen, refetch }: EntityPanelProps) => {
  const [myEvents, setEvents] = useState(Array<Event>);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState<any | undefined>(undefined);

  const { label, uuid, is_active } = entity;

  const handleSelectEvent = useCallback((event: Event) => {
    if (event.start > new Date()) {
      setEdit(true);
      event.comment = event.title;
      event.slotDuration = dayjs(event.end)
        .diff(event.start, 'minute')
        .toString();
      setFormData(event);
      setOpen(true);
    }
  }, []);

  const { loading, refetch: refetchEntity } = useQuery(GET_RESERVATIONS_BY_ID, {
    variables: { entity_uuid: uuid },
    onCompleted: (data) => {
      const eventsData = data.reservations.map((event: DbEvent) => ({
        id: event.uuid,
        start: dayjs(event.reserved_from).toDate(),
        end: dayjs(event.reserved_to).toDate(),
        title: event.comment,
      }));
      setEvents(eventsData);
    },
  });

  const handleSelectSlot = useCallback(
    ({ start, end }: { start: Date; end: Date }) => {
      if (start > new Date() && is_active) {
        setFormData({ start, end });
        setOpen(true);
      }
    },
    [setEvents]
  );

  const [updateEntity] = useMutation(UPDATE_EXISTING_ENTITY, {
    onCompleted: () => refetch(),
  });

  const { defaultDate, views, messages, additionalOptions, max, min } = useMemo(
    () => calendarProps(updateEntity, entity),
    []
  );

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  const tileDisabled = useCallback(
    (date: Date) => ({
      ...(date < new Date() && { style: { backgroundColor: 'lightgrey' } }),
    }),
    []
  );

  const eventPropGetter = useCallback(
    () => ({
      ...{
        style: {
          width: '100%',
          height: '50px',
        },
      },
    }),
    []
  );

  if (loading) return <SpinningLoader />;

  return (
    <div className={styles.root}>
      <div className={styles.label}>
        <span className={styles.nameHover}>{label}</span>
        <Button
          id="basic-button"
          aria-haspopup="true"
          onClick={() => handleOpen(entity, true)}
          sx={{ color: 'rgba(0,0,0,.87)' }}
        >
          <ModeEditIcon fontSize="small" />
        </Button>
        <ThreeDotMenuDelete
          id={{ id: entity.uuid }}
          type="deleteEntity"
          additionalOptions={additionalOptions}
          refetch={refetch}
        />
      </div>
      <div
        style={{ height: 500, width: '80%' }}
        className={!entity.is_active ? styles.disabled : undefined}
      >
        <Calendar
          dayLayoutAlgorithm="no-overlap"
          defaultDate={defaultDate}
          defaultView={Views.WEEK}
          views={views}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          messages={messages}
          localizer={localizer}
          events={myEvents}
          selectable
          dayPropGetter={tileDisabled}
          eventPropGetter={eventPropGetter}
          max={max}
          min={min}
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 250,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <EntitySlotForm
              edit={edit}
              editData={formData}
              handleClose={handleClose}
              refetch={refetchEntity}
              setEvents={setEvents}
              events={myEvents}
              entity={uuid}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default EntityPanel;
