import {
  StatusTypeInterface,
  GymMemberAttributesType,
} from '../interfaces/index';

import { DELETE_GYM_MEMBER } from '../graphQL/queries/members';
import { DELETE_GYM_EMPLOYEE } from '../graphQL/queries/employee';
import { DELETE_RESERVATION_ENTITY } from '../graphQL/queries/reservations';

export const menu = [
  {
    title: 'Početna stranica',
    url: '/dashboard/',
    visibleFor: ['superadmin', 'admin', 'user'],
  },
  {
    title: 'Tenant Administration',
    url: '/tenants-admin/',
    visibleFor: ['superadmin'],
  },
  {
    title: 'Administracija trenera',
    url: '/employees-admin/',
    visibleFor: ['superadmin', 'admin'],
  },
  {
    title: 'Administracija članova',
    url: '/members-admin/',
    visibleFor: ['superadmin', 'admin', 'user'],
  },
  {
    title: 'Administracija rezervacija',
    url: '/reservations-admin/',
    visibleFor: ['superadmin', 'admin', 'user'],
  },
];

export enum STATUS {
  ACTIVE = 0,
  INACTIVE,
  FROZEN,
  EXPIRED,
}

export const statusDescription: StatusTypeInterface = {
  [STATUS.ACTIVE]: { id: STATUS.ACTIVE, label: 'Aktivan', className: 'active' },
  [STATUS.INACTIVE]: {
    id: STATUS.INACTIVE,
    label: 'Neaktivan',
    className: 'inactive',
  },
  [STATUS.FROZEN]: {
    id: STATUS.FROZEN,
    label: 'Zamrznuta članarina',
    className: 'frozen',
  },
  [STATUS.EXPIRED]: {
    id: STATUS.EXPIRED,
    label: 'Istekla članarina',
    className: 'inactive',
  },
};

export const SUPERADMIN = {
  name: '(superadmin)',
};

export const GYM_MEMBER_ATTRIBUTES: GymMemberAttributesType = {
  name: 'Ime',
  surname: 'Prezime',
  created_at: 'Kreiran na dan',
  updated_at: 'Izmijenjen na dan',
  membership_id: 'Trajanje članarine',
  status: 'Status',
  comment: 'Komentar',
  membership_renewed: 'Datum obnove članarine',
  dob: 'Datum rođenja',
  contact: 'Podaci za kontakt',
  remaining_numbered_sessions: 'Preostali broj plaćenih treninga',
};

export interface ModalQueriesTypes {
  [key: string]: any;
}

export const CONFIRM_DELETE_MODAL_QUERIES: ModalQueriesTypes = {
  deleteGymMember: DELETE_GYM_MEMBER,
  deleteGymUser: DELETE_GYM_EMPLOYEE,
  deleteEntity: DELETE_RESERVATION_ENTITY,
};
