import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { Dayjs } from 'dayjs';

import SearchBar from '../../SearchBar';
import Row from './Row';

import {
  SortInterface,
  DbGymMember,
  HistoryDataInterface,
} from '../../../interfaces';

import styles from './collapsibleTable.module.scss';

interface CollapsibleTableProps {
  loading: boolean;
  data: DbGymMember[];
  handleOpen: (data: DbGymMember | undefined, edit: boolean) => void;
  handleNewMembership: (
    id: string | undefined,
    expiryDate: Dayjs | string
  ) => void;
  searchQuery: string | undefined;
  searchHandler: (searchString: string | undefined) => void;
  handleSort: (obj: SortInterface) => void;
  sortedColumn: SortInterface;
  history: {
    loadHistory: () => void;
    loadingHistory: boolean;
    historyData: { gym_members_logs: HistoryDataInterface[] };
  };
}

const CollapsibleTable = ({
  loading,
  data,
  searchQuery,
  handleOpen,
  handleNewMembership,
  searchHandler,
  handleSort,
  sortedColumn,
  history,
}: CollapsibleTableProps) => {
  const isVisible = data?.length > 0;

  // TODO websocket table updates

  return (
    <div className={styles.collapsibleTable}>
      <SearchBar searchHandler={searchHandler} searchQuery={searchQuery} />
      <TableContainer component={Paper} sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel
                  active={Object.keys(sortedColumn)[0] === 'name'}
                  direction={Object.values(sortedColumn)[0]}
                  onClick={() =>
                    handleSort({
                      name:
                        Object.values(sortedColumn)[0] === 'desc'
                          ? 'asc'
                          : 'desc',
                    })
                  }
                >
                  Ime i Prezime
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ maxWidth: '4rem' }}>
                <TableSortLabel
                  active={Object.keys(sortedColumn)[0] === 'membership_renewed'}
                  direction={Object.values(sortedColumn)[0]}
                  onClick={() =>
                    handleSort({
                      membership_renewed:
                        Object.values(sortedColumn)[0] === 'desc'
                          ? 'asc'
                          : 'desc',
                    })
                  }
                >
                  Datum zadnjeg produženja
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" sx={{ maxWidth: '4rem' }}>
                Datum isteka člananirne
              </TableCell>
              <TableCell align="center">Status članarine</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {!loading && data && isVisible && (
            <TableBody>
              {data.map((row: DbGymMember) => (
                <Row
                  handleNewMembership={handleNewMembership}
                  handleOpen={handleOpen}
                  key={row.id}
                  row={row}
                  history={history}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default CollapsibleTable;
