import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs, { Dayjs } from 'dayjs';
import Button from '@mui/material/Button';

import {
  membershipTypes,
  membershipID,
  membershipDuration,
} from '../MemberForm/constants';
import { EDIT_EXISTING_MEMBER } from '../../../graphQL/queries/members';

import styles from './newMembershipForm.module.scss';

interface MemberFormProps {
  id: string | undefined;
  handleClose: () => void;
  expiryDate: Dayjs | string;
  refetch: () => void;
}
interface IFormInput {
  id: string;
  membership_renewed: string;
  membership_type: number;
  membership_id: number;
  remaining_numbered_sessions: number;
  created_by: string;
  gym_location: string;
}

const NewMembershipForm = ({
  id,
  handleClose,
  expiryDate,
  refetch,
}: MemberFormProps) => {
  const { user } = useAuth0();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      membership_renewed: '',
      membership_type: membershipDuration.MONTHLY,
      membership_id: membershipID.MONTHLY,
      remaining_numbered_sessions: 0,
      created_by: user!.sub,
      gym_location: localStorage.getItem('g') ?? '',
    },
  });

  const [updateMembership] = useMutation(EDIT_EXISTING_MEMBER, {
    onCompleted: () => refetch(),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (type) {
      data.membership_type = membershipTypes[type].type;
      data.membership_id = membershipTypes[type].id;
    }
    data.membership_renewed = `${dayjs(data.membership_renewed).toString()}`;
    if (data.membership_id === membershipID.NUMBERED) {
      data.remaining_numbered_sessions = membershipTypes[type].duration;
    }

    data.created_by = user!.sub ?? '';
    updateMembership({
      variables: {
        id,
        set: data,
      },
    });

    handleClose();
  };

  const type = watch('membership_id');

  return (
    <form
      className={styles.newMembershipForm}
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3>Uređenje članarine</h3>
      <div className={styles.container}>
        <div>
          <p>Produži članarinu od:</p>
          <div className="radio">
            <input
              defaultChecked={true}
              {...register('membership_renewed')}
              type="radio"
              value={dayjs().format('YYYY-MM-DD')}
            />
            <label>Današnjeg dana</label>
          </div>
          <div className="radio">
            <input
              defaultChecked={false}
              {...register('membership_renewed')}
              type="radio"
              value={dayjs(expiryDate).format('YYYY-MM-DD')}
            />
            <label>
              Datuma isteka članarine ({dayjs(expiryDate).format('DD.MM.YYYY.')}
              )
            </label>
          </div>
        </div>
        <div
          className={
            errors.membership_id
              ? styles.membershipTypeError
              : styles.membershipType
          }
        >
          <p>Članarina</p>
          {membershipTypes.map((membership, index: number) => (
            <div key={`${membership.id}${membership.type}`} className="radio">
              <input
                {...register('membership_id', { required: true })}
                type="radio"
                value={index}
              />
              <label>{membership.description}</label>
            </div>
          ))}
        </div>
      </div>
      <div>
        <p>
          {Object.keys(errors)?.length > 0 && (
            <span className={styles.error}>
              Polja u crvenom su obavezna kod upisa novog člana!
            </span>
          )}
        </p>
      </div>
      <div className={styles.buttons}>
        <Button variant="contained" color="error" onClick={() => handleClose()}>
          Odustani
        </Button>
        <Button variant="contained" color="success" type="submit" size="large">
          Unesi
        </Button>
      </div>
    </form>
  );
};
export default NewMembershipForm;
