import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

interface GymInterface {
  name: string;
  uuid: string;
}
const gyms = [
  {
    name: 'Bazen',
    uuid: '30f85cbb-6344-412a-9b1e-6171314cf2c0',
  },
  {
    name: 'City Mall',
    uuid: '3daa1042-ebca-42ee-b106-8a5f46eb94f3',
  },
];

const Login = () => {
  const [defaultGym, setDefaultGym] = useState(localStorage.getItem('g') ?? '');
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const onChangeHandler = (e: SelectChangeEvent) => {
    setDefaultGym(e.target.value);
  };

  if (isAuthenticated) return null;

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Container maxWidth="xs">
        <FormControl fullWidth>
          <p>Odaberite gym</p>
          <Select value={defaultGym} onChange={onChangeHandler} fullWidth>
            {gyms.map((gym: GymInterface) => (
              <MenuItem key={gym.uuid} value={gym.uuid}>
                {gym.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            sx={{ marginTop: '1rem' }}
            variant="contained"
            size="large"
            onClick={() => {
              localStorage.setItem('g', defaultGym ?? '');
              loginWithRedirect({
                redirectUri: process.env.REACT_APP_REDIRECT_URI,
              });
            }}
            disabled={!defaultGym}
          >
            Login
          </Button>
        </FormControl>
      </Container>
    </Box>
  );
};

export default Login;
