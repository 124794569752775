import Header from '../Header';

import styles from './pageLayout.module.scss';

const PageLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Header />
      <div className={styles.pageLayout}>{children}</div>
    </>
  );
};

export default PageLayout;
