import Button from '@mui/material/Button';

interface AddNewButttonProps {
  handleOpen: (data: undefined, edit: boolean) => void;
  title: string;
}

const AddNewButton = ({ handleOpen, title }: AddNewButttonProps) => (
  <Button
    variant="contained"
    color="success"
    size="large"
    onClick={() => handleOpen(undefined, false)}
    sx={{ marginBottom: '1rem' }}
  >
    {title}
  </Button>
);

export default AddNewButton;
