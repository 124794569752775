import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import styles from './filter.module.scss';

import { STATUS } from '../../../constants/constants';

interface FilterProps {
  handleFilter: (num: STATUS) => void;
  filterStatus: number;
}

const Filter = ({ handleFilter, filterStatus }: FilterProps) => {
  return (
    <div className={styles.filter}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={() => handleFilter(-1)}
              checked={filterStatus === -1}
            />
          }
          label="Svi"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={() => handleFilter(STATUS.ACTIVE)}
              checked={filterStatus === STATUS.ACTIVE}
            />
          }
          label="Aktivan"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={() => handleFilter(STATUS.INACTIVE)}
              checked={filterStatus === STATUS.INACTIVE}
            />
          }
          label="Neaktivan"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={() => handleFilter(STATUS.FROZEN)}
              checked={filterStatus === STATUS.FROZEN}
            />
          }
          label="Zamrznut"
        />
      </FormGroup>
    </div>
  );
};

export default Filter;
