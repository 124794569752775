import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useAuth0 } from '@auth0/auth0-react';

import Logout from '../../components/Logout';

import { menu } from '../../constants/constants';

import styles from './header.module.scss';

const Header = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [value, setValue] = useState('/dashboard/');
  const location = useLocation();

  useEffect(() => {
    if (!location) return;
    setValue(location.pathname);
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate(newValue);
  };

  if (!user) return null;

  //  console.log('user', user);
  return (
    <div className={styles.header}>
      {user && (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {menu.map((menuItem) => {
              const isVisibleForCurrentRole = menuItem.visibleFor.some(
                (item) => item === user.defaultgymadminrole
              );
              if (isVisibleForCurrentRole) {
                return (
                  <Tab
                    key={menuItem.url}
                    label={menuItem.title}
                    value={menuItem.url}
                  />
                );
              }
              return null;
            })}
          </Tabs>
          <Logout />
        </>
      )}
    </div>
  );
};

export default Header;
