import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import PageTitle from '../PageTitle';
import AddNewButton from '../AddNewButton';
import EmployeeForm from './EmployeeForm';
import EmployeeTable from './EmployeeTable';
import SpinningLoader from '../SpinningLoader';

import { SEARCH_EMPLOYEES } from '../../graphQL/queries/employee';
import { DbGymEmployee, SortInterface } from '../../interfaces';

import styles from './employeesAdmin.module.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// TODO websocket table updates

const EmployeesAdmin = () => {
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState<DbGymEmployee | undefined>(
    undefined
  );
  const [open, setOpen] = useState(false);
  const [orderBy, setOrderBy] = useState<SortInterface>({
    first_name: 'desc',
  });

  const [loadEmployees, { data, loading, refetch }] = useLazyQuery(
    SEARCH_EMPLOYEES,
    {
      pollInterval: +process.env.REACT_APP_POLLINTERVAL! ?? 30000,
      variables: {
        order_by: orderBy ?? {
          first_name: 'desc',
        },
      },
    }
  );

  useEffect(() => {
    loadEmployees();
  }, []);

  const handleEdit = (data: DbGymEmployee | undefined, edit: boolean) => {
    if (data) setEditData(data);
    if (edit) {
      setEdit(true);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  const handleSort = (sortedColumn: SortInterface) => {
    setOrderBy(sortedColumn);
  };

  return (
    <>
      <PageTitle title={'Administracija zaposlenika'} />
      <div className={styles.tableContainer}>
        <div>
          <AddNewButton title="Novi zaposlenik" handleOpen={handleEdit} />
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              {open && (
                <EmployeeForm
                  edit={edit}
                  editData={editData}
                  handleClose={handleClose}
                  refetch={refetch}
                />
              )}
            </Box>
          </Fade>
        </Modal>
        {!data && <SpinningLoader />}
        {data && (
          <EmployeeTable
            loading={loading}
            data={data.gym_employees}
            handleOpen={handleEdit}
            handleSort={handleSort}
            sortedColumn={orderBy}
          />
        )}
      </div>
    </>
  );
};

export default EmployeesAdmin;
