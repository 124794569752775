import { gql } from '@apollo/client';

export const GET_ALL_RESERVATION_ENTITIES = gql(`query allEntities {
  reservation_entity(order_by: {created_at: asc}) {
    label
    uuid
    is_active
    created_at_gym
  }
}`);

export const INSERT_RESERVATIONS_ENTITY =
  gql(`mutation insertEntity($created_by: String!, $label: String!) {
    insert_reservation_entity(objects: { created_by: $created_by, label: $label}) {
        __typename
    }
}
`);

export const UPDATE_EXISTING_ENTITY =
  gql(`mutation updateEntity($uuid: uuid!, $set: reservation_entity_set_input) {
  update_reservation_entity_by_pk(pk_columns: {uuid: $uuid}, _set: $set) {
    uuid
  }
}
`);

export const DELETE_RESERVATION_ENTITY = gql(`
mutation deleteReservationEntity($uuid: uuid!) {
  delete_reservation_entity_by_pk(uuid: $uuid) {
    uuid
  }
}
`);

export const INSERT_RESERVATION = gql(`
mutation insertReservation(
  $comment: String!,
    $created_by: String!,
    $entity: uuid!,
    $reserved_from: timestamptz!,
    $reserved_to: timestamptz!,
    $gym_location: uuid!,
) {
  insert_reservations_one(object: {gym_location:$gym_location, comment:$comment, created_by:$created_by, entity: $entity, reserved_from:$reserved_from, reserved_to:$reserved_to}) {
    uuid
  }
}
`);

export const GET_RESERVATIONS_BY_ID = gql(`
query getAllReservationsById($entity_uuid: uuid!) {
  reservations(where: {entity: {_eq: $entity_uuid}}) {
    comment
    entity
    reserved_from
    reserved_to
    uuid
  }
}
`);

export const UPDATE_RESERVATIONS_BY_ID = gql(`
mutation updateReservationByPk($uuid: uuid!, $set: reservations_set_input!) {
  update_reservations_by_pk(pk_columns: {uuid: $uuid}, _set: $set) {
    uuid
  }
}
`);

export const DELETE_RESERVATION = gql(`
mutation deleteReservation($uuid: uuid!) {
  delete_reservations_by_pk(uuid: $uuid) {
    uuid
  }
}
`);
