import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';

import styles from './logout.module.scss';

const Logout = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <div className={styles.logout}>
      {isAuthenticated && (
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          Logout
        </Button>
      )}
    </div>
  );
};

export default Logout;
