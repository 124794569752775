import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const SpinningLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        height: '25vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress sx={{ width: '100%', height: '100%' }} />
    </Box>
  );
};

export default SpinningLoader;
