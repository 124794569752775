import { gql } from '@apollo/client';

export const INSERT_EMPLOYEE =
  gql(`mutation insertEmployee($employee_role: String!, $password: String!, $phone: String!, $email: String!, $first_name: String!, $last_name: String!) {
  insertEmployee(employee: {employee_role: $employee_role, password: $password, phone: $phone, email: $email, first_name: $first_name, last_name: $last_name}) {
    status
  }
}`);

export const SEARCH_EMPLOYEES =
  gql(`query searchGymEmployees($order_by: gym_employees_order_by!) {
  gym_employees(order_by: [$order_by], where: {employee_role: {_neq: "superadmin"}}) {
    uuid
    phone
    first_name
    last_name
    email
    employee_role
    status
    updated_by
    updated_at
    created_by
    created_at
    user_id
    createdBy {
      first_name
      last_name
    }
    updatedBy {
      first_name
      last_name
    }
  }
}
`);

export const UPDATE_EXISTING_EMPLOYEE =
  gql(`mutation updateEmployee($uuid:uuid!, $set: gym_employees_set_input) {
    update_gym_employees_by_pk(pk_columns: {uuid: $uuid}, _set: $set) {
      uuid
    }
  }
`);

export const DELETE_GYM_EMPLOYEE = gql(`
mutation deleteEmployee($user_id: String!, $uuid: uuid!) {
  deleteEmployee(user_id: $user_id, uuid: $uuid) {
    status
  }
}
`);
