import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: any;
}) {
  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Container maxWidth="sm">
        <div role="alert">
          <p>Something went wrong:</p>
          <pre>{error.message}</pre>
          <Button variant="contained" onClick={resetErrorBoundary}>
            Try again
          </Button>
        </div>
      </Container>
    </Box>
  );
}

export default ErrorFallback;
