import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import DeleteConfirmationModal from './DeleteConfirmationModal';

interface ThreeDotMenuProps {
  id: { id: string } | { uuid: string; user_id: string };
  type: string;
  additionalOptions?: Array<{ handler: any; label: string }>;
  refetch?: () => void;
}

interface DeleteTypes {
  [key: string]: any;
}
const deleteTypes: DeleteTypes = {
  deleteGymMember: {
    type: 'deleteGymMember',
    message: 'Obriši člana',
  },
  deleteGymUser: {
    type: 'deleteGymUser',
    message: 'Obriši zaposlenika',
  },
  deleteEntity: {
    type: 'deleteEntity',
    message: 'Obriši djelatnost',
  },
};

const ThreeDotMenuDelete = ({
  id,
  type,
  additionalOptions = undefined,
  refetch,
}: ThreeDotMenuProps) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
  };
  const handleClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
  };

  return (
    <div>
      {open && (
        <DeleteConfirmationModal
          open={openDelete}
          id={id}
          onClose={handleClose}
          type={deleteTypes[type].type}
          refetch={refetch}
        />
      )}
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: 'rgba(0,0,0,.87)' }}
      >
        <MoreVertIcon fontSize="small" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleDeleteClick}>
          {deleteTypes[type].message}
        </MenuItem>
        {additionalOptions?.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              option.handler();
              setAnchorEl(null);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ThreeDotMenuDelete;
