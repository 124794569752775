import { SetStateAction, Dispatch } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';

import {
  INSERT_RESERVATION,
  UPDATE_RESERVATIONS_BY_ID,
  DELETE_RESERVATION,
} from '../../../graphQL/queries/reservations';
import { Event } from '../interfaces';

import styles from '../EntityForm/entityForm.module.scss';

interface EmployeeFormProps {
  edit: boolean;
  editData: Event;
  handleClose: () => void;
  refetch: () => void;
  setEvents: Dispatch<SetStateAction<Event[]>>;
  events: Event[];
  entity: string;
}

interface IFormInput {
  uuid: string;
  comment: string;
  slotDuration: string;
}

const EntitySlotForm = ({
  edit,
  editData,
  handleClose,
  refetch,
  setEvents,
  events,
  entity,
}: EmployeeFormProps) => {
  const { user } = useAuth0();

  if (!user) return null;

  const user_id = user.sub;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm<IFormInput>({
    defaultValues:
      edit && editData
        ? editData
        : {
            comment: '',
            slotDuration: '30',
          },
  });

  const gqlQuery = edit ? UPDATE_RESERVATIONS_BY_ID : INSERT_RESERVATION;

  const [insertEntity] = useMutation(gqlQuery, {
    onCompleted: () => refetch(),
  });

  const [deleteQuery] = useMutation(DELETE_RESERVATION);

  const handleDeleteEntry = () => {
    const variables = { uuid: editData.id };
    deleteQuery({ variables });
    setEvents((events) => events.filter((item) => item.id !== editData.id));
    refetch();
    handleClose();
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!data) return;
    const endEvent = dayjs(editData.start)
      .add(parseInt(slotDuration), 'minute')
      .toDate();
    const overlap = events.some(
      (otherEvent: Event) =>
        editData.start < otherEvent.end &&
        endEvent > otherEvent.start &&
        otherEvent.id !== editData.id
    );
    if (overlap) {
      setError('slotDuration', {
        type: 'pattern',
        message: 'Unešeni termin se preklapa sa ranije unesenim terminima.',
      });
    }
    if (edit) {
      const variables = {
        uuid: editData.id,
        set: {
          comment: data.comment,
          reserved_from: dayjs(editData.start).toDate(),
          reserved_to: dayjs(editData.start)
            .add(parseInt(slotDuration), 'minute')
            .toDate(),
        },
      };
      insertEntity({ variables });
      handleClose();
    }

    if (!edit) {
      const endOfSlot = dayjs(editData.start)
        .add(parseInt(slotDuration), 'minute')
        .toDate();
      setEvents((prev) => [
        ...prev,
        {
          start: editData.start,
          end: endOfSlot,
          slotDuration,
          title: data.comment,
        },
      ]);
      const variables = !edit
        ? {
            created_by: user_id,
            comment: data.comment,
            reserved_from: editData.start,
            reserved_to: endOfSlot,
            entity,
            gym_location: localStorage.getItem('g') ?? '',
          }
        : { uuid: data.uuid, set: { comment: data.comment } };
      insertEntity({ variables });
      handleClose();
    }
  };

  const first_name = watch('comment');
  const slotDuration = watch('slotDuration');

  return (
    <>
      {editData && (
        <form
          autoComplete="off"
          className={styles.container}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.personalData}>
            <h3>Podaci o rezervaciji</h3>
            <h4>
              Rezervacija za: {dayjs(editData.start).format('DD-MM-YYYY HH:mm')}
            </h4>
            <br />
            <input
              className={errors.slotDuration ? styles.error : undefined}
              {...register('slotDuration', {
                required: true,
                pattern: /^\d+$/,
              })}
            />
            <label className={slotDuration ? styles.notEmpty : undefined}>
              Trajanje termina u minutama:
            </label>
            <input
              className={errors.comment ? styles.error : undefined}
              {...register('comment', { required: true })}
            />
            <label
              className={first_name?.length > 0 ? styles.notEmpty : undefined}
            >
              Komentar:
            </label>
          </div>
          <div>
            <p>
              {Object.keys(errors)?.length > 0 && (
                <span className={styles.error}>
                  {errors?.slotDuration?.message ??
                    'Unesite osnovne informacije o rezerviranom terminu.'}
                </span>
              )}
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleClose()}
            >
              Odustani
            </Button>
            <Button
              variant="contained"
              color="success"
              type="submit"
              size="large"
            >
              Unesi
            </Button>{' '}
          </div>
          {edit && (
            <div style={{ width: '100%', paddingTop: '16px' }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDeleteEntry()}
              >
                Obriši
              </Button>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default EntitySlotForm;
