import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { updatedDiff } from 'deep-object-diff';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';

import { statusDescription, STATUS } from '../../../constants/constants';
import { membershipTypes, membershipID, membershipDuration } from './constants';
import {
  EDIT_EXISTING_MEMBER,
  INSERT_NEW_MEMBER,
  SEARCH_MEMBERS,
} from '../../../graphQL/queries/members';
import { DbGymMember } from '../../../interfaces';

import styles from './memberForm.module.scss';

interface MemberFormProps {
  edit: boolean;
  editData?: DbGymMember;
  handleClose: () => void;
}
interface IFormInput {
  name: string;
  surname: string;
  dob: string | null;
  membership_type: number;
  membership_id: number;
  contact: string;
  comment: string;
  status: number;
  created_by: string;
  remaining_numbered_sessions: number | null;
  gym_location: string;
}
const customParseFormat = require('dayjs/plugin/customParseFormat');

const MemberForm = ({ edit, editData, handleClose }: MemberFormProps) => {
  const { user } = useAuth0();
  dayjs.extend(customParseFormat);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: edit
      ? editData
      : {
          name: '',
          surname: '',
          dob: null,
          contact: '',
          comment: '',
          membership_type: membershipDuration.MONTHLY,
          membership_id: membershipID.MONTHLY,
          status: 0,
          created_by: user!.sub,
          remaining_numbered_sessions: null,
          gym_location: localStorage.getItem('g') ?? '',
        },
  });

  const gqlQuery = edit ? EDIT_EXISTING_MEMBER : INSERT_NEW_MEMBER;
  const [insertNewMember] = useMutation(gqlQuery, {
    refetchQueries: [SEARCH_MEMBERS],
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!data) return;
    data.created_by = user!.sub ?? '';
    if (type) {
      data.membership_type = membershipTypes[type].type;
      data.membership_id = membershipTypes[type].id;
    }
    if (data.dob === '') data.dob = null;
    if (
      data.membership_id === membershipID.NUMBERED &&
      data.remaining_numbered_sessions === 0
    ) {
      data.remaining_numbered_sessions = membershipTypes[type].duration;
    }

    const gym_location = localStorage.getItem('g') ?? '';

    const dob =
      edit && editData
        ? dayjs(editData.dob, 'D.M.YYYY.').format('YYYY-MM-DD')
        : dayjs(data.dob, 'D.M.YYYY.').format('YYYY-MM-DD');
    const variables =
      edit && editData
        ? {
            id: editData.id,
            set: {
              ...updatedDiff(editData, data),
              gym_location,
              dob,
            },
          }
        : { ...data, dob };

    insertNewMember({
      variables,
    });
    handleClose();
  };

  const name = watch('name');
  const surname = watch('surname');
  const dateOfBirth = watch('dob');
  const contact = watch('contact');
  const type = watch('membership_id');

  return (
    <form
      autoComplete="off"
      className={styles.memberForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.container}>
        <h3>Podaci o članu</h3>
        <div className={styles.generalInfo}>
          <div className={styles.leftBlock}>
            <div className={styles.personalData}>
              <input
                className={errors.name ? styles.error : undefined}
                {...register('name', { required: true })}
              />
              <label className={name?.length > 0 ? styles.notEmpty : undefined}>
                Ime:
              </label>{' '}
              <input
                className={errors.surname ? styles.error : undefined}
                {...register('surname', { required: true })}
              />
              <label
                className={surname?.length > 0 ? styles.notEmpty : undefined}
              >
                Prezime:
              </label>
              <input
                className={errors.dob ? styles.error : undefined}
                {...register('dob', {
                  pattern: /^$|([0-9]{1,2}.[0-9]{1,2}.[0-9]{4}.)/,
                })}
              />
              <label
                className={
                  dateOfBirth !== null && dateOfBirth?.length > 0
                    ? styles.notEmpty
                    : undefined
                }
              >
                Datum rođenja:
              </label>
              <input
                className={errors.contact ? styles.error : undefined}
                {...register('contact', {
                  required: true,
                })}
              />
              <label
                className={contact?.length > 0 ? styles.notEmpty : undefined}
              >
                Kontakt:
              </label>
            </div>
            {edit && (
              <div className={styles.status}>
                Status:
                {Object.keys(statusDescription).map((statusType: string) => {
                  if (statusDescription[statusType].id !== STATUS.EXPIRED) {
                    return (
                      <div
                        className={styles.radio}
                        key={statusDescription[statusType].id}
                      >
                        <input
                          {...register('status')}
                          defaultChecked={
                            statusDescription[statusType].id ===
                            editData?.status
                          }
                          type="radio"
                          value={statusDescription[statusType].id}
                        />
                        <label>{statusDescription[statusType].label}</label>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
            {editData?.membership_id === membershipID.NUMBERED &&
              editData?.remaining_numbered_sessions - 1 >= 0 && (
                <div className={styles.status}>
                  <p>
                    Preostali broj dolazaka:{' '}
                    {editData.remaining_numbered_sessions}
                  </p>
                  <input
                    {...register('remaining_numbered_sessions')}
                    type="radio"
                    value={editData.remaining_numbered_sessions - 1}
                  />
                  <label>Umanji za jedan</label>
                </div>
              )}
          </div>
          <div
            className={
              errors.membership_type
                ? styles.membershipTypeError
                : styles.membershipType
            }
          >
            <strong>Članarina: </strong>
            {membershipTypes.map((membership, index) => (
              <div
                key={`${membership.id}${membership.type}`}
                className={styles.radio}
              >
                <input
                  defaultChecked={
                    edit && editData
                      ? membership.id === editData.membership_id
                      : membership.id === membershipID.MONTHLY
                  }
                  {...register('membership_type')}
                  type="radio"
                  value={index}
                />
                <label>{membership.description}</label>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.commentary}>
          <label>Komentar</label>
          <textarea {...register('comment')}></textarea>
        </div>
        <p>
          {Object.keys(errors)?.length > 0 && (
            <span className={styles.error}>
              Polja u crvenom su obavezna kod upisa novog člana!
            </span>
          )}
        </p>
      </div>
      <div className={styles.buttons}>
        <Button variant="contained" color="error" onClick={handleClose}>
          Odustani
        </Button>
        <Button variant="contained" color="success" type="submit" size="large">
          Unesi
        </Button>
      </div>
    </form>
  );
};
export default MemberForm;
