import { useState } from 'react';
import { useQuery } from '@apollo/client';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

import PageTitle from '../PageTitle';
import SpinningLoader from '../SpinningLoader';
import AddNewButton from '../AddNewButton';
import EntityForm from './EntityForm';
import TabPanel from './TabPanel';
import EntityPanel from './EntityPanel';

import { Entity } from './interfaces';

import { GET_ALL_RESERVATION_ENTITIES } from '../../graphQL/queries/reservations';

import styles from './reservationsAdmin.module.scss';

// TODO use this as import
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ReservationsAdmin = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState<Entity | undefined>(undefined);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (data = undefined, editOld = false) => {
    setEdit(editOld);
    setOpen(true);
    setFormData(data);
  };

  const { data, loading, refetch } = useQuery(GET_ALL_RESERVATION_ENTITIES, {
    onCompleted: () => {
      setTabIndex(0);
    },
  });

  if (loading || !data) return <SpinningLoader />;

  return (
    <>
      <PageTitle title="Pregled rezervacija" />
      <div className={styles.tableContainer}>
        <div>
          <AddNewButton title="Nova djelatnost" handleOpen={handleOpen} />
        </div>
        {data.reservation_entity?.length === 0 && (
          <p>
            Nemate uneseno nijednu djelatnost koju je moguće rezervirati,
            unesite novu.
          </p>
        )}
        <Box sx={{ width: '100%' }} pl={4} pr={2}>
          <Tabs value={tabIndex} onChange={handleChange}>
            {data.reservation_entity?.map((entity: Entity) => (
              <Tab key={entity.uuid} label={entity.label} />
            ))}
          </Tabs>
          {data.reservation_entity?.map((entity: Entity, index: number) => (
            <TabPanel key={entity.uuid} value={tabIndex} index={index}>
              <EntityPanel
                entity={entity}
                handleOpen={handleOpen}
                refetch={refetch}
              />
            </TabPanel>
          ))}
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={() => handleClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 250,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <EntityForm
                edit={edit}
                editData={formData}
                handleClose={handleClose}
                refetch={refetch}
              />
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default ReservationsAdmin;
