import { ErrorBoundary } from 'react-error-boundary';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';

import ErrorFallback from './components/ErrorFallback';
import Dashboard from './components/Dashboard';
import MembersAdmin from './components/MembersAdmin';
import PageLayout from './components/PageLayout';
import Home from './components/Home';
import EmployeesAdmin from './components/EmployeesAdmin';
import ReservationsAdmin from './components/ReservationsAdmin';
import RoleRoute from './components/RoleRoute';

import { AuthorizedApolloProvider } from './graphQL/ApolloProvider/AuthorizedApolloProvider';
import { menu } from './constants/constants';

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.error(error, info);
};
const ProtectedRoute = ({ component, ...args }: { component: any }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={process.env.REACT_APP_REDIRECT_URI}
      onRedirectCallback={onRedirectCallback}
      leeway={120}
    >
      {children}
    </Auth0Provider>
  );
};

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback>
          <AuthorizedApolloProvider>
            <PageLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="dashboard"
                  element={<ProtectedRoute component={Dashboard} />}
                />
                <Route
                  path="tenants-admin"
                  element={
                    <RoleRoute
                      roles={
                        menu.find((item) => item.url === '/tenants-admin/')
                          ?.visibleFor
                      }
                    >
                      <ProtectedRoute component={Dashboard} />
                    </RoleRoute>
                  }
                />
                <Route
                  path="employees-admin"
                  element={
                    <RoleRoute
                      roles={
                        menu.find((item) => item.url === '/employees-admin/')
                          ?.visibleFor
                      }
                    >
                      <ProtectedRoute component={EmployeesAdmin} />
                    </RoleRoute>
                  }
                />
                <Route
                  path="members-admin"
                  element={<ProtectedRoute component={MembersAdmin} />}
                />
                <Route
                  path="reservations-admin"
                  element={<ProtectedRoute component={ReservationsAdmin} />}
                />
                {/* 404 page */}
                <Route path="*" element={<Home />} />
              </Routes>
            </PageLayout>
          </AuthorizedApolloProvider>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
