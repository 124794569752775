import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import dayjs, { Dayjs } from 'dayjs';

import ThreeDotMenuDelete from '../../ThreeDotMenuDelete';
import {
  STATUS,
  statusDescription,
  GYM_MEMBER_ATTRIBUTES,
} from '../../../constants/constants';
import { DbGymMember, HistoryDataInterface } from '../../../interfaces';
import {
  membershipTypes,
  dateModifier,
  membershipDuration,
  membershipID,
} from '../MemberForm/constants';
import { formatOutput } from './helpers';

import styles from './collapsibleTable.module.scss';

interface RowProps {
  row: DbGymMember;
  handleOpen: (data: DbGymMember | undefined, edit: boolean) => void;
  handleNewMembership: (id: string | undefined, expiryDate: Dayjs) => void;
  history: {
    loadHistory: ({
      variables: { uuid },
    }: {
      variables: { uuid: string };
    }) => void;
    loadingHistory: boolean;
    historyData: { gym_members_logs: HistoryDataInterface[] };
  };
}

const Row = ({ row, handleOpen, handleNewMembership, history }: RowProps) => {
  const [open, setOpen] = useState(false);
  const { loadHistory, loadingHistory, historyData } = history;

  const membershipType = membershipTypes.filter((item) => {
    return item.type === row.membership_type && item.id === row.membership_id;
  })[0];

  if (membershipType === undefined) return null;

  const expiryDate = dayjs(row.membership_renewed).add(
    membershipType.duration,
    dateModifier[row.membership_type]
  );

  const isMembershipExpired =
    row.membership_type === membershipDuration.VIP
      ? false
      : row.membership_id === membershipID.NUMBERED &&
        row.remaining_numbered_sessions - 1 >= 0
      ? false
      : expiryDate.diff(dayjs(), 'day') < 0;

  const statusObject = isMembershipExpired
    ? statusDescription[STATUS.EXPIRED]
    : statusDescription[row.status];

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (!open) loadHistory({ variables: { uuid: row.id } });
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          onClick={() => handleOpen(row, true)}
          component="th"
          scope="row"
          className={styles.nameHover}
          align="left"
        >
          <div className={styles.flex}>
            <div className={styles.nameSurname}>
              {row.name} {row.surname}
            </div>
            <div className={styles.editIcon}>
              <ModeEditIcon />
            </div>
          </div>
        </TableCell>
        <TableCell align="center">
          {dayjs(row.membership_renewed).format('DD.MM.YYYY.')}
        </TableCell>
        <TableCell align="right">
          {membershipType.id === membershipID.VIP
            ? 'Doživotna'
            : membershipType.id === membershipID.NUMBERED
            ? `Preostalo: ${row.remaining_numbered_sessions} dolazaka`
            : expiryDate.format('DD.MM.YYYY.')}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleNewMembership(row.id, expiryDate)}
          className={styles.statusHover}
        >
          <span
            className={`${styles.status} ${styles[statusObject.className]}`}
          >
            {statusObject.label}
          </span>
        </TableCell>
        <TableCell align="right">
          <ThreeDotMenuDelete id={{ id: row.id }} type="deleteGymMember" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h5" gutterBottom component="div">
                Povijest
              </Typography>
              {row.comment && (
                <Typography variant="h6" gutterBottom component="div">
                  Komentar za člana: {row.comment}
                </Typography>
              )}
              {!loadingHistory &&
                historyData &&
                historyData.gym_members_logs.length > 0 && (
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Zadnja izmjena</TableCell>
                        <TableCell>Izmijenio</TableCell>
                        <TableCell align="left">Promijenjeni podatak</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyData.gym_members_logs.map((entry) => (
                        <TableRow key={entry.id}>
                          <TableCell component="th" scope="row">
                            {dayjs(entry.created_at).format('DD.MM.YYYY.')}
                          </TableCell>
                          <TableCell>
                            {entry.createdBy.first_name}{' '}
                            {entry.createdBy.last_name}
                          </TableCell>
                          <TableCell align="left">
                            {Object.keys(entry.fields_changed).map(
                              (key: string, index: number) => {
                                if (!GYM_MEMBER_ATTRIBUTES[key]) return null;
                                return (
                                  <p key={index}>{`${
                                    GYM_MEMBER_ATTRIBUTES[key]
                                  }: ${formatOutput(
                                    key,
                                    entry.fields_changed[key]
                                  )}`}</p>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
