import { gql } from '@apollo/client';

export const GET_ALL_MEMBERS = gql(`
query getAllGymMembers {
    gym_members(order_by: {membership_renewed: desc}) {
        created_at
        created_by
        id
        membership_id
        membership_renewed
        membership_type
        comment
        name
        status
        surname
        updated_by
        updated_at
        dob
        contact
        remaining_numbered_sessions
      }
  }`);

export const INSERT_NEW_MEMBER = gql(`
mutation insertNewMember($remaining_numbered_sessions: Int, $contact: String, $dob: date, $comment: String = "", $created_by: String, $created_at: timestamptz = "now()", $membership_type: Int, $membership_id: Int, $name: String = "", $surname: String = "", $status: Int, $gym_location: uuid!) {
    insert_gym_members_one(object: {remaining_numbered_sessions: $remaining_numbered_sessions, contact: $contact, dob: $dob, comment: $comment, name: $name, surname: $surname, created_at: $created_at, membership_renewed: $created_at, updated_by: $created_by, created_by: $created_by, membership_id: $membership_id, membership_type: $membership_type, status: $status, gym_location: $gym_location}) {
      id
    }
  }
`);

export const EDIT_EXISTING_MEMBER = gql(`
mutation editMember($id:uuid!, $set: gym_members_set_input) {
    update_gym_members_by_pk(pk_columns: {id: $id}, _set: $set) {
      id
    }
  }
`);

export const SEARCH_MEMBERS = gql(`
    query searchForUser($order_by: gym_members_order_by!, $where: gym_members_bool_exp) {
        gym_members(order_by: [$order_by], where: $where) {
            created_at
            created_by
            id
            membership_id
            membership_renewed
            membership_type
            comment
            name
            status
            surname
            updated_by
            updated_at
            dob
            contact
            remaining_numbered_sessions
            createdByEmployee {
                first_name
                last_name
            }
            updatedByEmployee {
                first_name
                last_name
            }
        }
    }
`);

export const GET_HISTORY_OF_MEMBER = gql(`
query queryHistoryLogOfUser($uuid: String!) {
  gym_members_logs(where: {uuid: {_eq: $uuid}}, order_by: {created_at: desc}) {
    created_by
    created_at
    fields_changed
    id
    createdBy {
      first_name
      last_name
    }
  }
}
`);

export const DELETE_GYM_MEMBER = gql(`
mutation editMember($id: uuid!) {
  delete_gym_members_by_pk(id: $id) {
    id
  }
}
`);
