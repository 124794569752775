import Login from '../Login';

const Home = () => {
  return (
    <>
      <div>HomePAGE</div>
      <Login />
    </>
  );
};

export default Home;
