import { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import ThreeDotMenuDelete from '../../ThreeDotMenuDelete';

import { SUPERADMIN } from '../../../constants/constants';
import { DbGymEmployee } from '../../../interfaces';

import styles from './employeeTable.module.scss';

interface RowProps {
  row: DbGymEmployee;
  handleEdit: (employeeObject: DbGymEmployee, flag: boolean) => void;
}

const Row = ({ row, handleEdit }: RowProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          onClick={() => handleEdit(row, true)}
          component="th"
          scope="row"
          className={styles.nameHover}
          align="left"
        >
          <div className={styles.flex}>
            <div className={styles.nameSurname}>
              {row.first_name} {row.last_name}
            </div>
            <div className={styles.editIcon}>
              <ModeEditIcon />
            </div>
          </div>
        </TableCell>
        <TableCell align="right">{row.phone || '--'}</TableCell>
        <TableCell align="right">{row.email || '--'}</TableCell>
        <TableCell align="right">
          <ThreeDotMenuDelete
            id={{ uuid: row.uuid, user_id: row.user_id }}
            type="deleteGymUser"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Povijest
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Zadnja izmjena</TableCell>
                    <TableCell>Izmjenio</TableCell>

                    <TableCell align="right">Dodao kao zaposlenika</TableCell>
                    <TableCell align="right">
                      Datum dodavanja kao zaposlenika
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.uuid}>
                    <TableCell component="th" scope="row">
                      {dayjs(row.updated_at).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell>
                      {row.updatedBy?.first_name
                        ? `${row.updatedBy.first_name} ${row.updatedBy.last_name}`
                        : SUPERADMIN.name}
                    </TableCell>
                    <TableCell align="right">
                      {row.createdBy?.first_name
                        ? `${row.createdBy.first_name} ${row.createdBy.last_name}`
                        : SUPERADMIN.name}
                    </TableCell>
                    <TableCell align="right">
                      {dayjs(row.created_at).format('DD.MM.YYYY')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
