import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';

import Row from './Row';

import { DbGymEmployee } from '../../../interfaces';

import styles from './employeeTable.module.scss';

interface EmployeeTableProps {
  loading: boolean;
  data: [];
  handleOpen: (data: DbGymEmployee, edit: boolean) => void;
  handleSort: (column: { [key: string]: 'desc' | 'asc' | undefined }) => void;
  sortedColumn: { [key: string]: 'desc' | 'asc' | undefined };
}

const EmployeeTable = ({
  loading,
  data,
  handleOpen,
  handleSort,
  sortedColumn,
}: EmployeeTableProps) => {
  const isVisible = data?.length > 0;

  return (
    <div className={styles.collapsibleTable}>
      <TableContainer component={Paper} sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel
                  active={Object.keys(sortedColumn)[0] === 'name'}
                  direction={Object.values(sortedColumn)[0]}
                  onClick={() =>
                    handleSort({
                      first_name:
                        Object.values(sortedColumn)[0] === 'desc'
                          ? 'asc'
                          : 'desc',
                    })
                  }
                >
                  Ime i Prezime
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Broj telefona</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {!loading && data && isVisible && (
            <TableBody>
              {data.map((row: DbGymEmployee) => {
                return <Row key={row.uuid} row={row} handleEdit={handleOpen} />;
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default EmployeeTable;
