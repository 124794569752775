import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';

import MemberForm from './MemberForm';
import CollapsibleTable from './CollapsibleTable';
import LeftSidebar from './LeftSidebar';
import PageTitle from '../PageTitle';
import NewMembershipForm from './NewMembershipForm';

import {
  SEARCH_MEMBERS,
  GET_HISTORY_OF_MEMBER,
} from './../../graphQL/queries/members';
import { DbGymMember, SortInterface } from '../../interfaces';

import styles from './membersAdmin.module.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface NewMembershipStateType {
  visible: boolean;
  id: undefined | string;
  expiryDate: Dayjs | string;
}
const MembersAdmin = () => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newMembership, setNewMembership] = useState<NewMembershipStateType>({
    visible: false,
    id: undefined,
    expiryDate: '',
  });
  const [editData, setEditData] = useState<DbGymMember | undefined>();
  const [searchedString, setSearchedString] = useState<string | undefined>('');
  const [orderBy, setOrderBy] = useState<SortInterface>({
    membership_renewed: 'desc',
  });
  const [filter, setFilter] = useState(-1);

  const status = filter !== -1 ? { _eq: filter } : {};
  const _or = searchedString
    ? {
        _or: [
          { name: { _ilike: `${searchedString!}%` } },
          { surname: { _ilike: `${searchedString!}%` } },
        ],
      }
    : {};

  const [loadMembers, { data, loading, error, refetch }] = useLazyQuery(
    SEARCH_MEMBERS,
    {
      pollInterval: +process.env.REACT_APP_POLLINTERVAL! ?? 30000,
      variables: {
        where: { status, _or },
        order_by: orderBy,
      },
    }
  );

  const [loadHistory, { loading: loadingHistory, data: historyData }] =
    useLazyQuery(GET_HISTORY_OF_MEMBER, { fetchPolicy: 'network-only' });

  useEffect(() => {
    loadMembers();
  }, []);

  useEffect(() => {
    if (!searchedString) return;
    loadMembers();
  }, [searchedString]);

  const handleEdit = (data: DbGymMember | undefined, edit: boolean) => {
    if (data) setEditData(data);
    if (edit) {
      setEdit(true);
    }
    setOpen(true);
  };

  const handleSort = (sortedColumn: SortInterface) => {
    setOrderBy(sortedColumn);
  };

  const handleNewMembership = (
    id: string | undefined,
    expiryDate: Dayjs | string
  ) => {
    setNewMembership({ visible: true, id, expiryDate });
    setEdit(false);
    setOpen(true);
  };

  const handleFilter = (status: number) => {
    setFilter(status);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setNewMembership({ visible: false, id: undefined, expiryDate: '' });
  };

  const searchHandler = (searchString: string | undefined) => {
    setSearchedString(searchString);
  };

  if (error) {
    console.error('error', error);
    return null;
  }

  const transformedData: DbGymMember[] = data?.gym_members.map(
    (member: DbGymMember) => {
      return { ...member, dob: dayjs(member.dob).format('DD.MM.YYYY.') };
    }
  );

  return (
    <>
      <PageTitle title="Pregled članova" />
      <div className={styles.tableContainer}>
        <LeftSidebar
          handleOpen={handleEdit}
          handleFilter={handleFilter}
          filterStatus={filter}
        />
        <CollapsibleTable
          loading={loading}
          data={transformedData}
          handleOpen={handleEdit}
          handleNewMembership={handleNewMembership}
          searchQuery={searchedString}
          searchHandler={searchHandler}
          handleSort={handleSort}
          sortedColumn={orderBy}
          history={{
            loadHistory,
            loadingHistory,
            historyData,
          }}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={() => handleClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 250,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              {!newMembership.visible && open && (
                <MemberForm
                  edit={edit}
                  editData={editData}
                  handleClose={handleClose}
                />
              )}
              {newMembership.visible && (
                <NewMembershipForm
                  handleClose={handleClose}
                  id={newMembership.id}
                  expiryDate={newMembership.expiryDate}
                  refetch={refetch}
                />
              )}
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default MembersAdmin;
