import AddNewButton from '../../AddNewButton';
import Filter from '../Filter';

import { DbGymMember } from '../../../interfaces';

import styles from './leftSidebar.module.scss';

interface LeftSidebarProps {
  handleOpen: (data: DbGymMember | undefined, edit: boolean) => void;
  handleFilter: (status: number) => void;
  filterStatus: number;
}

const LeftSidebar = ({
  handleOpen,
  handleFilter,
  filterStatus,
}: LeftSidebarProps) => {
  return (
    <div className={styles.leftSidebar}>
      <AddNewButton title="Novi član" handleOpen={handleOpen} />
      <Filter filterStatus={filterStatus} handleFilter={handleFilter} />
    </div>
  );
};

export default LeftSidebar;
