import styles from './pageTitle.module.scss';

const PageTitle = ({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <div className={styles.pageTitle}>
      <span className={styles.title}>{title}</span>
      {children}
    </div>
  );
};

export default PageTitle;
