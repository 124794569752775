import { useState } from 'react';
import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { CONFIRM_DELETE_MODAL_QUERIES } from '../../constants/constants';

interface DeleteConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  id: any;
  type?: string;
  refetch?: () => void;
}
const DeleteConfirmationModal = ({
  open,
  onClose,
  id,
  type = 'deleteGymMember',
  refetch,
}: DeleteConfirmationModalProps) => {
  const [deleteGymMember] = useMutation(CONFIRM_DELETE_MODAL_QUERIES[type], {
    onCompleted: () => {
      if (refetch) refetch();
    },
  });
  const [confirmEnabled, setConfirmEnabled] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== 'obriši' && confirmEnabled) {
      setConfirmEnabled(false);
    }
    if (e.target.value === 'obriši') setConfirmEnabled(true);
  };
  const variables = type === 'deleteEntity' ? { uuid: id.id } : { ...id };

  const onConfirm = () => {
    deleteGymMember({ variables });
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box p={2} sx={{ width: '400px' }}>
        <DialogTitle>Jeste li sigurni?</DialogTitle>
        <TextField
          fullWidth
          label={'upišite "obriši" za potvrdu'}
          margin="normal"
          variant="filled"
          onChange={handleChange}
        />
        <Stack direction="row" justifyContent="space-between">
          <Button variant="contained" color="error" onClick={handleClose}>
            Odustani
          </Button>
          <Button
            disabled={!confirmEnabled}
            variant="contained"
            color="success"
            onClick={onConfirm}
          >
            Prihvati
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
