import dayjs from 'dayjs';

import { membershipTypes } from '../MemberForm/constants';
import { statusDescription } from '../../../constants/constants';

export const formatOutput = (key: string, value: any): string => {
  switch (key) {
    case 'membership_renewed':
      return value ? dayjs(value).format('DD.MM.YYYY.') : '(nije upisano)';
    case 'dob':
      return value ? dayjs(value).format('DD.MM.YYYY.') : '(nije upisano)';
    case 'created_at':
      return value ? dayjs(value).format('DD.MM.YYYY.') : '(nije upisano)';
    case 'updated_at':
      return value ? dayjs(value).format('DD.MM.YYYY.') : '(nije upisano)';
    case 'comment':
      return value && value.length > 0 ? value : '(nema)';
    case 'membership_id':
      return (
        membershipTypes.find((type) => type.id === value)?.description ?? ''
      );
    case 'status':
      return statusDescription[value]?.label ?? '';
    default:
      return value ?? '';
  }
};
