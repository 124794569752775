import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';

import {
  INSERT_RESERVATIONS_ENTITY,
  UPDATE_EXISTING_ENTITY,
} from '../../../graphQL/queries/reservations';

import styles from './entityForm.module.scss';

interface EmployeeFormProps {
  edit: boolean;
  editData?: { label: string };
  handleClose: () => void;
  refetch: () => void;
}

interface IFormInput {
  uuid: string;
  label: string;
}

const EntityForm = ({
  edit,
  editData,
  handleClose,
  refetch,
}: EmployeeFormProps) => {
  const { user } = useAuth0();
  if (!user) return null;
  const user_id = user.sub;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues:
      edit && editData
        ? editData
        : {
            label: '',
          },
  });

  const gqlQuery = edit ? UPDATE_EXISTING_ENTITY : INSERT_RESERVATIONS_ENTITY;
  const [insertEntity] = useMutation(gqlQuery, {
    onCompleted: () => refetch(),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!data) return;
    const variables = !edit
      ? { created_by: user_id, label: data.label }
      : { uuid: data.uuid, set: { label: data.label } };
    insertEntity({ variables });
    handleClose();
  };

  const first_name = watch('label');

  return (
    <>
      <form
        autoComplete="off"
        className={styles.container}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.personalData}>
          <h3>Unesite ime djelatnosti</h3>
          <input
            className={errors.label ? styles.error : undefined}
            {...register('label', { required: true })}
          />
          <label
            className={first_name?.length > 0 ? styles.notEmpty : undefined}
          >
            Djelatnost:
          </label>
        </div>
        <div>
          <p>
            {Object.keys(errors)?.length > 0 && (
              <span className={styles.error}>
                Polja u crvenom su obavezna kod upisa novog člana!
              </span>
            )}
          </p>
        </div>
        <div className={styles.buttons}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose()}
          >
            Odustani
          </Button>
          <Button
            variant="contained"
            color="success"
            type="submit"
            size="large"
          >
            Unesi
          </Button>
        </div>
      </form>
    </>
  );
};

export default EntityForm;
